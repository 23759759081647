import Chartjs from 'chart.js/auto'
import 'chartjs-adapter-moment'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100"

Chartjs.defaults.font.family = 'Arial'
Chartjs.register(ChartjsPluginStacked100)

const tooltipPlugin = Chartjs.registry.getPlugin('tooltip')
tooltipPlugin.positioners.miniclient = function(tooltips, position) {
  if (Array.isArray(tooltips) && tooltips.length > 0) {
    let canvas = this.chart.canvas
    let tooltip = tooltips[0].element
    if (position.x + tooltip.x > canvas.clientWidth) {
      return {x: canvas.clientWidth - tooltip.x, y: position.y}
    } else if (position.x < 80) {
      return {x: tooltip.x, y: position.y}
    }
  }
  return {x: position.x, y: position.y}
}

class Chart extends React.Component {

  chartObject = null
  data = null
  canvasRef = React.createRef()

  componentDidMount() {
    this.data = MC.extend({}, this.props.data.param)
    this.initializeChart(this.props)
  }

  componentDidUpdate() {
    if (JSON.stringify(this.data) != JSON.stringify(this.props.data.param)) {
      this.data = MC.extend({}, this.props.data.param)
      this.destroyChart()
      this.initializeChart(this.props)
    }
  }

  componentWillUnmount() {
    this.destroyChart();
  }

  destroyChart() {
    this.chartObject && this.chartObject.destroy();
  }

  initializeChart(props) {
    let defaultColors = ['#36A2EB', '#FF6384', '#FFCE56', '#33C379', '#0063E4', '#DD3B3B', '#F0D193', '#94F686', '#0088B7', '#A90C13', '#DCB927', '#ABCB00']
    let type = props.type
    let pluginsConf = {}
    let legPos = MC.getFieldParamValue(props.data.param, '@legendPosition')
    pluginsConf.legend = {}
    pluginsConf.legend.display = (legPos == 'none' ? false : true)
    if (['right', 'left', 'top', 'bottom', 'none'].indexOf(legPos) < 0) {
      legPos = 'top'
    }
    pluginsConf.legend.position = MC.isNull(legPos) || legPos == 'none' ? 'top' : legPos
    let maintainAspectRatio = true
    if (MC.getFieldParamBooleanValue(props.data.param, '@maintainAspectRatio') === false) {
      maintainAspectRatio = false
    }
    let plugins = []
    let padding = {}
    if (MC.getFieldParamBooleanValue(props.data.param, '@valuesInsideChart')) {
      plugins.push(ChartDataLabels)
      let labelsColor = MC.getFieldParamValue(props.data.param, '@valuesInsideChartColor') || '#FFFFFF'
      let pos = MC.getFieldParamValue(props.data.param, '@valuesInsideChartPosition') || 'center'
      if (pos == 'start') {
        pluginsConf.datalabels = {color: labelsColor, anchor: 'start', align: 'end'}
      } else if (pos == 'end') {
        let horizontal = MC.getFieldParamBooleanValue(props.data.param, '@horizontal')
        let offset = horizontal ? 3 : -3
        pluginsConf.datalabels = {anchor: 'end', offset: offset, align: 'end', clamp: true, color: labelsColor}
        if (type == 'pie') {
          padding = 20
        } else {
          if (horizontal) {
            padding.right = 40
          } else {
            padding.top = 20
          }
        }
      } else {
        pluginsConf.datalabels = {color: labelsColor}
      }
      let hideSmaller = MC.getFieldParamValue(props.data.param, '@valuesInsideChartHideSmallerThan')
      let formatter = function(value) {
        if (hideSmaller && Number(value) < Number(hideSmaller)) {
          return ""
        }
        return value
      }  
      if (MC.getFieldParamValue(props.data.param, '@valuesInsideChartFormatter') == 'percent') {
        formatter = function(value, ctx) {
          if (value === undefined || value === null || value == 0) {
            return ""
          }
          let values = []
          let index
          if (type == 'pie') {
            ctx.chart.data.datasets[0].data.forEach(d => {
              if (d !== undefined && d !== null && d != 0) {
                values.push(d)
              } 
            })
            index = ctx.dataIndex
          } else {
            ctx.chart.data.datasets.forEach(ds => {
              if (ds.data[0] !== undefined && ds.data[0] !== null && ds.data[0] != 0) {
                values.push(ds.data[0])
              }
            })
            index = ctx.datasetIndex
          }
          let sum = values.reduce((partialSum, a) => partialSum + Number(a), 0)
          let pectentageCorrection = 0
          if (index == values.length -1) {
            let percentageSum = values.reduce((partialSum, a) => partialSum + Math.round(a*100 / sum), 0)
            pectentageCorrection = 100 - percentageSum
          }
          let percentage = ((value*100 / sum) + pectentageCorrection).toFixed(0)
          if (hideSmaller && Number(percentage) < Number(hideSmaller)) {
            return ""
          }
          return percentage + "%"
        }
      }
      if (MC.getFieldParamValue(props.data.param, '@valuesInsideChartFormatter') == 'message') {
        let pattern = MC.getFieldParamValue(props.data.param, '@valuesInsideChartformatPattern')
        formatter = function(value, ctx) {
          if (value === undefined || value === null || hideSmaller && Number(value) < Number(hideSmaller)) {
            return ""
          }
          return MC.formatValue(null, 'message', null, pattern, {param: {value: value}, lang: props.data.flow.lang})
        }    
      }
      pluginsConf.datalabels.formatter = formatter
    }
    let tooltips = true
    if (MC.getFieldParamBooleanValue(props.data.param, '@showChartTooltips') === false) {
      tooltips = false
    }
    if (type == 'pie') {
      let items = MC.getFieldParamValue(props.data.param, 'values') || []
      let values = []
      let labels = []
      let colors = []
      for (let item of MC.asArray(items)) {
        values.push(item['@amount'])
        labels.push(item['@name'])
        colors.push(item['@color'])
      }
      labels = MC.isNull(labels) ? null : labels
      colors = MC.isNull(colors) ? defaultColors : colors
      if (Array.isArray(values) && values.length > colors.length) {
        colors = this.fillColors(colors, values.length)
      }
      let data = {
        labels: labels,
        datasets: [{
          data: values,
          backgroundColor: colors
        }]
      }
      let cutoutPercentage = MC.getFieldParamValue(props.data.param, '@cutoutPercentage')
      if (MC.isNull(cutoutPercentage)) {
        cutoutPercentage = 0
      }
      pluginsConf.tooltip = {enabled: tooltips, position: 'miniclient', caretSize: 0, backgroundColor: "#FFFFFF", bodyColor: "#516C85", borderColor: "#E3EDED", borderWidth: 1, padding: 10}
      this.chartObject = new Chartjs(this.canvasRef.current, {
        type: 'pie',
        plugins: plugins,
        data: data,
        options: {
          maintainAspectRatio: maintainAspectRatio,
          cutout: cutoutPercentage + '%',
          plugins: pluginsConf,
          animation: false,
          layout: { padding: padding }
        }
      })
    } else if (type == 'basic') {
      let labels = MC.getFieldParamValue(props.data.param, 'xAxis/c')
      labels = MC.isNull(labels) ? null : MC.asArray(labels)
      let ysoftmin = MC.getFieldParamValue(props.data.param, 'yAxis/softMin')
      ysoftmin = MC.isNumeric(ysoftmin) ? ysoftmin : null
      let ysoftmax = MC.getFieldParamValue(props.data.param, 'yAxis/softMax')
      ysoftmax = MC.isNumeric(ysoftmax) ? ysoftmax : null 
      let ysoftminSecondary = MC.getFieldParamValue(props.data.param, 'yAxisSecondary/softMin')
      ysoftminSecondary = MC.isNumeric(ysoftminSecondary) ? ysoftminSecondary : null
      let ysoftmaxSecondary = MC.getFieldParamValue(props.data.param, 'yAxisSecondary/softMax')
      ysoftmaxSecondary = MC.isNumeric(ysoftmaxSecondary) ? ysoftmaxSecondary : null 
      let timeUnit = MC.getFieldParamValue(props.data.param, 'xAxis/timeUnit')
      let stacked = MC.getFieldParamBooleanValue(props.data.param, '@stacked') || false
      if (MC.getFieldParamBooleanValue(props.data.param, '@stacked100')) {
        stacked = true
        pluginsConf.stacked100 = { enable: true, precision: 2}
      }
      let type = MC.getFieldParamValue(props.data.param, '@chartType')
      let series = MC.getFieldParamValue(props.data.param, 'series') || []
      series = MC.asArray(series)
      let useSecondaryYaxis = false
      let datasets = []
      for (let i = 0; i < series.length ; i++) {
        let serie = series[i]
        let dataset = {}
        dataset.type = serie['@type'] ? serie['@type'] : 'bar'
        dataset.data = serie.d
        dataset.label = serie['@name'] ? serie['@name'] : ''
        let color = serie['@color'] ? serie['@color'] : defaultColors[i % defaultColors.length]
        dataset.borderColor = color
        dataset.backgroundColor = color
        if (dataset.type == 'line') {
          if (["default", "monotone"].indexOf(serie['@interpolationMode']) > -1) {
            dataset.cubicInterpolationMode = serie['@interpolationMode']
          } else {
            dataset.lineTension = 0
          }
          if (["origin", "start", "end"].indexOf(serie['@fill']) > -1) {
            dataset.fill = serie['@fill']
            if (serie['@backgroundColor']) {
              dataset.backgroundColor = serie['@backgroundColor']
            }
          } else {
            dataset.fill = false
          }
        }
        if (true === serie['@isSecondary']) {
          dataset.yAxisID = 'ysecondary'
          useSecondaryYaxis = true
        } else {
          dataset.yAxisID = 'y'
        }
        dataset.borderWidth = dataset.type == 'bar' ? 0 : 2
        datasets.push(dataset)
      }
      let yGridLine = true
      if (MC.getFieldParamBooleanValue(props.data.param, 'yAxis/gridLine') === false) {
        yGridLine = false
      }
      let yBorderLine = true
      if (MC.getFieldParamBooleanValue(props.data.param, 'yAxis/borderLine') === false) {
        yBorderLine = false
      }
      let yGridLineColor = MC.getFieldParamValue(props.data.param, 'yAxis/gridLineColor') || "#CECECE"
      let yAxisShow = true
      if (MC.getFieldParamBooleanValue(props.data.param, 'yAxis/hide') === true) {
        yAxisShow = false
      }
      let scales = {}
      scales.y = {display: yAxisShow, id: 'y', stacked: stacked, position: 'left', suggestedMin: ysoftmin, suggestedMax: ysoftmax, grid: {display: yGridLine, color: yGridLineColor}, border: {display: yBorderLine}}
      if (useSecondaryYaxis) {
        let ySGridLine = true
        if (MC.getFieldParamBooleanValue(props.data.param, 'yAxisSecondary/gridLine') === false) {
          ySGridLine = false
        }
        let ySBorderLine = true
        if (MC.getFieldParamBooleanValue(props.data.param, 'yAxisSecondary/borderLine') === false) {
          ySBorderLine = false
        }
        let ySGridLineColor = MC.getFieldParamValue(props.data.param, 'yAxisSecondary/gridLineColor') || "#CECECE"
        scales.ysecondary = {id: 'ysecondary', stacked: stacked, position: 'right', suggestedMin: ysoftminSecondary, suggestedMax: ysoftmaxSecondary, grid: {display: ySGridLine, color: ySGridLineColor}, border: {display: ySBorderLine}}
      }
      type = (type == 'line' ? 'line' : 'bar')
      let xGridLine = true
      if (MC.getFieldParamBooleanValue(props.data.param, 'xAxis/gridLine') === false) {
        xGridLine = false
      }
      let xBorderLine = true
      if (MC.getFieldParamBooleanValue(props.data.param, 'xAxis/borderLine') === false) {
        xBorderLine = false
      }
      let xGridLineColor = MC.getFieldParamValue(props.data.param, 'xAxis/gridLineColor') || "#CECECE"
      let xAxisShow = true
      if (MC.getFieldParamBooleanValue(props.data.param, 'xAxis/hide') === true) {
        xAxisShow = false
      }
      scales.x = {display: xAxisShow, stacked: stacked, grid: {display: xGridLine, color: xGridLineColor}, border: {display: xBorderLine}}
      if (!MC.isNull(timeUnit)) {
        scales.x.type = 'time'
        scales.x.distribution = 'linear'
        let time = {}
        time.unit = timeUnit
        let timeMin = MC.getFieldParamValue(props.data.param, 'xAxis/timeMin')
        if (!MC.isNull(timeMin)) {
          time.min = timeMin
        }
        let timeMax = MC.getFieldParamValue(props.data.param, 'xAxis/timeMax')
        if (!MC.isNull(timeMax)) {
          time.max = timeMax
        }
        scales.x.time = time
      }
      pluginsConf.tooltip = {
        enabled: tooltips,
        mode: "x",
        intersect: false,
        position: "nearest",
        backgroundColor: "#FFFFFF",
        titleColor: "#000000",
        titleFont: "normal",
        titleMarginBottom: 10,
        bodyColor: "#516C85",
        bodySpacing: 5,
        borderColor: "#E3EDED",
        borderWidth: 1,
        padding: 10,
        displayColors: false,
        callbacks: {
          labelTextColor:function(context) {
            return context.dataset.borderColor
          }
        },
        filter: function(item, arg1, context, data) {
          data = data.datasets[item.datasetIndex].data[item.dataIndex]
          return !isNaN(data) && data !== null
        }
      }
      let indexAxis = MC.getFieldParamBooleanValue(props.data.param, '@horizontal') ? 'y' : 'x'
      if (indexAxis == 'y') {
        scales.x.suggestedMin = ysoftmin
        scales.x.suggestedMax = ysoftmax
      }
      this.chartObject = new Chartjs(this.canvasRef.current, {
        type: type,
        plugins: plugins,
        data: {
          labels: labels,
          datasets: datasets
        },
        options: {
          indexAxis: indexAxis,
          maintainAspectRatio: maintainAspectRatio,
          responsive: true,
          scales: scales,
          plugins: pluginsConf,
          animation: false,
          layout: { padding: padding }
        }
      })
    }
  }

  fillColors(colors, size) {
    var ncolors = [];
    for (var i = 0; i < size; i++) {
      if (i<colors.length) {
        ncolors.push(colors[i]);
      } else {
        ncolors.push(colors[i % colors.length]);
      }
    }
    return ncolors;
  }

  render() {
    return <canvas data-widget-i-name={this.props.data.id} ref={this.canvasRef}/>
  }

}

MC.registerReactRomponent('chart', Chart)